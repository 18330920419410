/** @format */

import { propertyMap } from 'model-mapper';
import { File } from '../classes/file';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';
import { EmbededRealEstateStructure } from './real-estate-structure';
import { EmbededEquipment } from './equipment';

export enum UserAnomalyReportFileKind {
  PICTURE = 'picture',
  VIDEO = 'video',
}

export class UserAnomalyReportOccurence {
  @propertyMap({ type: Date })
  date: Date;

  @propertyMap()
  userId: string;

  @propertyMap({ type: File })
  picture: File | null;

  @propertyMap({ type: File })
  video: File | null;

  @propertyMap()
  comment: string | null;
}

export class UserAnomalyReport {
  @propertyMap({ type: UserAnomalyReport })
  nature: UserAnomalyReport;

  @propertyMap({ type: EmbededOrganizationalUnit })
  organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({ type: EmbededRealEstateStructure })
  realEstateStructure: EmbededRealEstateStructure;

  @propertyMap({ type: EmbededEquipment })
  equipment: EmbededEquipment;

  @propertyMap({ type: UserAnomalyReportOccurence })
  occurences: UserAnomalyReportOccurence;

  @propertyMap({ type: Date })
  createdAt: Date;

  @propertyMap({ type: Date })
  closedAt: Date | null;
}
